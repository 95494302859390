var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dashboard-list"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Dashboard一覧")]),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0",staticStyle:{"align-text":"right !important"}},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","to":{ name: 'dashboard-create' }}},[_c('span',[_vm._v("NEW")])])],1)],1),_c('v-data-table',{ref:"Dashboards",attrs:{"headers":_vm.headers,"items":_vm.dashboardList},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('dashboard-preview',{attrs:{"id":item.id},on:{"cancel":_vm.loadData}})]}},{key:"item.dashboard_image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-2"},[_c('v-img',{staticClass:"me-3",attrs:{"src":item.dashboard_image ? ("" + (item.dashboard_image)) : require('@/assets/images/noimage.jpg'),"max-height":"50","max-width":"50"}})],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.createdTime(item)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"py-2 pl-1"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('dashboard-delete',{staticClass:"py-2 pl-1",attrs:{"id":item.id,"icons":_vm.icons},on:{"deleted":_vm.loadData,"cancel":_vm.loadData}})],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }